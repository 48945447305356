import React, { useContext, useEffect, useRef, useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import api from '~/services/api';
import numbers from '~/utils/numbers';
import dates from '~/utils/dates';
import readXlsx from '~/utils/readXlsx';
import schema from './schema';
import ConfirmSubmit from '~/components/forms/ConfirmSubmit';
import './styles.css';
import { ModalContext } from '~/contexts';

// Interface
const FormBackupEnergy: React.FC<any> = () => {
    const [datesArr, setDates] = useState<any>(null);
    const [file, setFile] = useState<any>(null);
    const [date, setDate] = useState<any>(null);
    const [infos, setInfos] = useState<any>(null);
    const fileRef = useRef<HTMLInputElement>(null);

    const [check, setCheck] = useState<boolean>(false);
    const [checkExistDate, setcheckExistDate] = useState<boolean>(false);
    const { setIsOpen } = useContext(ModalContext);

    async function show(datacheck: any) {
        setInfos(null);
        setCheck(false);

        if (file) {
            let data: any = await readXlsx.getXlsx(file, date, schema);
            try {
                const res = await api.post(`save/uploads/save_bkp_power/${datacheck}`, data);
                //console.log(res)
                setIsOpen(true)
                setInfos(data);
                setFile(null);
                setCheck(false)
                setcheckExistDate(false)
            } catch (error) {
                //console.error(error);
                setCheck(true)
                setcheckExistDate(true)
            }
        }
    }

    useEffect(() => {
        let datesBase = dates.getMonthsYear();
        setDates(datesBase);
        setDate(datesBase[0]);
    }, [])

    return (
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="uploadProinfa">
            {
                check &&
                <ConfirmSubmit checkExistDate={checkExistDate} date={date} show={show} setIsOpen={setIsOpen}  type={"da Energia de Reserva"}/>
            }
            {!check &&
                <>
                    <div className="fileBox">
                        <input id="inputFile" name="inputFile" type="file" onChange={ev => setFile(ev.target.files ? ev.target.files[0] : null)} ref={fileRef} />

                        <button className="selectFile" onClick={() => { fileRef.current?.click(); setInfos(null) }}>
                            {file ? file.name : 'Clique aqui para selecionar o arquivo!'}
                        </button>

                        <select name="date" id="date" onChange={ev => setDate(JSON.parse(ev.currentTarget.value))}>
                            {
                                datesArr && datesArr.map((el: any) => (
                                    <option key={`months_${el.year}-${el.month}`} value={JSON.stringify(el)}>{el.name} de {el.year}</option>
                                ))
                            }
                        </select>

                        <button className='saveFile' onClick={() => setCheck(true)} disabled={!file}>
                            <FiUpload size={20} />
                        </button>
                    </div>

                    <hr />

                    <div className="content liquid">
                        {
                            infos ? (
                                <div className='infoBox'>
                                    <div className="infoHeader">
                                        <div className="client">Name</div>
                                        <div className="cnpj">CNPJ</div>
                                        <div className="date">Mês</div>
                                        <div className="value">Valor</div>
                                    </div>

                                    {
                                        infos.map((el: any) => (
                                            <div className="infoLine" key={`box_${el.cnpj}`}>
                                                <div className="client">{el.client}</div>
                                                <div className="cnpj">{el.cnpj}</div>
                                                <div className="date">{((el.date.getMonth() + 1) <= 9 ? '0' : '') + (el.date.getMonth() + 1)}/{el.date.getFullYear()}</div>
                                                <div className="value">{numbers.money(el.value)}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            ) :
                                <div className="alert">Faça upload do arquivo!</div>
                        }
                    </div>
                </>
            }
        </form>
    );
}

export default FormBackupEnergy;